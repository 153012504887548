<template>
  <div>
    <el-main>
      <el-form>
        <el-row type="flex" justify="end">
          <el-col :span="2">
            <el-form-item>
              <el-button
                type="primary"
                @click="openDialog('add')"
                v-permission="['admin:evaluationTemplate:add']"
                >新增</el-button
              >
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>

      <!-- 表格 -->

      <el-table
        border
        style="width: 100%"
        :data="tableData"
        v-loading="tableLoading"
      >
        <el-table-column label="模版名称" prop="name"></el-table-column>
        <el-table-column label="模板简述" prop="description"></el-table-column>
        <el-table-column label="创建人" prop="creatorName"></el-table-column>
        <el-table-column label="创建日期" prop="createDate">
          <template slot-scope="scope">
            <span>{{ scope.row.createDate | formatDateFilter }}</span>
          </template>
        </el-table-column>
        <el-table-column
          label="最后更新人"
          prop="updaterName"
        ></el-table-column>
        <el-table-column label="最后更新日期" prop="updateDate">
          <template slot-scope="scope">
            <span>{{ scope.row.updateDate | formatDateFilter }}</span>
          </template>
        </el-table-column>
        <el-table-column label="操作" fixed="right" width="280">
          <template slot-scope="scope">
            <el-button
              type="primary"
              size="mini"
              v-permission="['admin:evaluationTemplate:edit']"
              @click="openDialog('edit', scope.row)"
              >编辑</el-button
            >
            <el-button
              type="primary"
              size="mini"
              v-permission="['admin:evaluationTemplate:copy']"
              @click="copyEvaluationTemplateOne(scope.row)"
              v-loading.fullscreen.lock="fullscreenLoading"
              >复制</el-button
            >
            <el-button
              type="primary"
              size="mini"
              v-permission="['admin:evaluationTemplate:detail']"
              @click="openDetailDialog('detail', scope.row)"
              >详情</el-button
            >
            <el-button
              type="primary"
              size="mini"
              v-permission="['admin:evaluationTemplate:delete']"
              @click="deleteTemplate(scope.row)"
              >删除</el-button
            >
          </template>
        </el-table-column>
      </el-table>
      <div class="page-info-class">
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="this.pageNum"
          :page-sizes="[5, 10, 20, 30, 50]"
          :page-size="this.pageSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="this.total"
        ></el-pagination>
      </div>
    </el-main>

    <!-- 编辑 -->
    <edit
      v-if="editDialog"
      :editDialog="editDialog"
      :type="typeDialog"
      v-on:listenEditClose="listenEditClose"
      :info="info"
    ></edit>
    <evaluationTemplateDetail
      v-if="detailDialog"
      :detailDialog="detailDialog"
      :type="typeDialog"
      v-on:listenDetailClose="listenDetailClose"
      :info="info"
    ></evaluationTemplateDetail>
  </div>
</template>

<script>
import {
  getEvaluationTemplateList,
  deleteEvaluationTemplate,
  copyEvaluationTemplate,
} from "@/api/evaluationTemplate";
import edit from "@/components/evaluationTemplate/edit";
import evaluationTemplateDetail from "@/components/evaluationTemplate/evaluationTemplateDetail";
import { formatDate } from "@/common/date";

export default {
  name: "evaluationTemplate",
  data() {
    return {
      tableData: [],
      editDialog: false, //打开编辑
      detailDialog: false, //打开编辑
      buttonControlDialog: false,
      info: {},
      typeDialog: "",
      tableLoading: false,
      total: 0,
      pageNum: 1,
      pageSize: 10,
      pageCount: 1,
      fullscreenLoading: false
    };
  },
  filters: {
    formatDateFilter(time) {
      var date = new Date(time);
      return formatDate(date, "yyyy-MM-dd hh:mm:ss");
    },
  },
  components: {
    edit,
    evaluationTemplateDetail,
  },

  mounted() {
    this.getEvaluationTemplateList(1, this.pageSize);
  },

  methods: {
    copyEvaluationTemplateOne(row) {
      this.fullscreenLoading = true;
      let info = {
        id: row.id,
        name: row.name,
        description: row.description,
      };
      copyEvaluationTemplate(info).then((response) => {
        if (response.success) {
          this.$message({
            type: "success",
            message: "复制成功!",
          });
          this.fullscreenLoading = false;
          let data = {type:"copy",...response.data}
          this.openDialog("edit", data );
          // this.getEvaluationTemplateList(this.pageNum, this.pageSize);
        } else {
          this.$message({
            type: "info",
            message: response.message,
          });
        }
      });
    },
    handleSizeChange(val) {
      this.getEvaluationTemplateList(1, val);
    },
    handleCurrentChange(val) {
      if (val > this.pageCount) {
        val = this.pageCount;
      }
      this.getEvaluationTemplateList(val, this.pageSize);
    },

    getEvaluationTemplateList(num, pageSize) {
      this.tableData = [];
      let query = {};
      query.pageNum = num;
      query.pageSize = pageSize;
      this.tableLoading = true;
      getEvaluationTemplateList(query).then((response) => {
        this.tableData = response.data.data;
        if (response.data.total != null) {
          this.pageNum = response.data.pageNum;
          this.pageSize = response.data.pageSize;
          this.total = response.data.total;
          this.pageCount = response.data.pageCount;
        } else {
          this.total = 0;
        }
        this.tableLoading = false;
      });
    },
    //删除
    deleteTemplate(row) {
      this.$confirm("确定要删除模版[" + row.name + "] 吗？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
        center: false,
      })
        .then(() => {
          deleteEvaluationTemplate({ id: row.id }).then((response) => {
            if (response.success) {
              this.$message({
                type: "success",
                message: "删除成功!",
              });
              this.getEvaluationTemplateList(this.pageNum, this.pageSize);
            } else {
              this.$message({
                type: "info",
                message: response.message,
              });
            }
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
    //新增编辑弹窗显示
    openDialog(type, row) {
      // console.log(id);
      this.typeDialog = type;
      if (type == "edit") {
        this.info = row;
        console.log(row)
      } else if (type == "add") {
        this.info = {};
      } else if (type == "copy") {
        this.info = row;
      }
      this.editDialog = true;
    },
    openDetailDialog(type, row) {
      // console.log(id);
      this.typeDialog = type;
      this.info = row;
      this.detailDialog = true;
    },
    //监听编辑弹窗关闭
    listenEditClose(params) {
      console.log(params);
      const { type, isClose } = params;
      this.editDialog = !isClose;
      this.getEvaluationTemplateList(this.pageNum, this.pageSize);

      // if (type == "sure") {
      //   this.getEvaluationTemplateList(this.pageNum, this.pageSize);
      // } else if (type == "cancel") {
      //   // this.getEvaluationTemplateList(this.pageNum, this.pageSize);
      // }
    },
    listenDetailClose(params) {
      console.log(params);
      const { type, isClose } = params;
      this.detailDialog = !isClose;
    },
  },
};
</script>

<style lang="scss" scoped>
</style>
