<template>
  <el-dialog
    class="edit"
    :title="title"
    :visible="editDialog"
    center
    @close="dialogClose"
    width="1000px"
    :close-on-click-modal="false"
  >
    <el-tabs v-model="activeName" @tab-click="handleClick">
      <el-tab-pane name="first">
        <span slot="label">模板结构 </span>
        <el-container>
          <el-header v-show="modularTop">
            <el-form
              :model="formData"
              ref="editEvaluationTemplate"
              :rules="rules"
              class="addDailog"
              onsubmit="return false;"
              label-width="140px"
              label-position="right"
            >
              <el-form-item label="模版名称" prop="name">
                <el-input
                  type="text"
                  show-word-limit
                  v-model="formData.name"
                  @blur="editEvaluationTemplateName($event, 'name')"
                ></el-input>
              </el-form-item>
              <el-form-item label="模版描述" prop="description">
                <el-input
                  type="text"
                  show-word-limit
                  v-model="formData.description"
                  @blur="editEvaluationTemplateName($event, 'description')"
                ></el-input>
              </el-form-item>
              <el-form-item class="itemOne" style="">
                <span v-show="activeName == 'first'" class=""
                  >小提示：本页面输入框可自动提交</span
                >
              </el-form-item>
              <el-form-item class="roleCodeList" label="模块" prop="data">
                <el-row type="flex" justify="start">
                  <el-col :span="2">
                    <el-button
                      size="mini"
                      class="modularButton"
                      @click="addNode"
                      >添加模块</el-button
                    >
                  </el-col>
                </el-row>
                <div class="custom-tree-container">
                  <div class="block">
                    <el-tree
                      :data="formData.treeElements"
                      node-key="id"
                      default-expand-all
                      :expand-on-click-node="false"
                      @node-click="nodeclick"
                      @node-drag-start="handleDragStart"
                      @node-drag-enter="handleDragEnter"
                      @node-drag-leave="handleDragLeave"
                      @node-drag-over="handleDragOver"
                      @node-drag-end="handleDragEnd"
                      @node-drop="handleDrop"
                      draggable
                      :allow-drop="allowDrop"
                      :allow-drag="allowDrag"
                    >
                      <span
                        class="custom-tree-node"
                        slot-scope="{ node, data }"
                      >
                        <!-- 如果是编辑状态 -->
                        <template v-if="data.isEdit == 1">
                          <el-input
                            ref="submitEditInput"
                            @change="() => submitEdit(node, data)"
                            v-model="newname"
                            style="height: 20px; line-height: 20px"
                          ></el-input>
                          <!-- 放弃、提交按钮废弃，改为失去焦点自动提交 -->
                          <!-- <el-button type="text"
              size="mini"
              @click="() => cancelEdit(node,data)">C</el-button>
            <el-button type="text"
              size="mini"
              @click="() => submitEdit(node,data)">S</el-button> -->
                        </template>
                        <!-- 如果不是编辑状态 -->
                        <span
                          v-if="data.level != 3 && data.isEdit != 1"
                          class="breakWord breakWord2 beyondDisplay1"
                          v-text="data.name"
                        ></span>
                        <el-tooltip
                          v-if="data.level == 3 && data.isEdit != 1"
                          :content="data.name"
                          placement="top"
                          effect="light"
                        >
                          <!-- <el-button>Light</el-button> -->
                          <span
                            class="breakWord breakWord2 beyondDisplay1"
                            v-text="data.name"
                          ></span>
                        </el-tooltip>

                        <span>
                          <el-button
                            type="text"
                            size="mini"
                            @click="() => edit(node, data)"
                          >
                            <i class="el-icon-edit"></i>
                          </el-button>
                          <el-button
                            v-if="data.level != 3"
                            type="text"
                            size="mini"
                            @click="() => append(node, data)"
                          >
                            <i class="el-icon-circle-plus-outline"></i>
                          </el-button>
                          <el-button
                            type="text"
                            size="mini"
                            @click="() => remove(node, data)"
                          >
                            <i class="el-icon-delete"></i>
                          </el-button>
                        </span>
                      </span>
                    </el-tree>
                  </div>
                </div>
              </el-form-item>
            </el-form>
          </el-header>
          <div class="modularCenter">
            <el-button
              v-show="modularTop"
              class="modularButton"
              @click="getNewTree"
              v-loading.fullscreen.lock="fullscreenLoading"
              >下一步</el-button
            >

            <el-page-header
              v-show="!modularTop"
              @back="getLastStep"
              content="点击评估元素，设置评分标准"
            >
            </el-page-header>
          </div>
          <el-main v-show="!modularTop" class="modularBottom">
            <div class="left">
              <el-input placeholder="输入关键字进行过滤" v-model="filterText">
              </el-input>

              <el-tree
                class="filter-tree"
                :data="newTree"
                :props="defaultProps"
                default-expand-all
                :expand-on-click-node="false"
                :filter-node-method="filterNode"
                ref="tree"
                @node-click="evaluationElementsClick2"
              >
                <div class="custom-tree-node" slot-scope="{ data }">
                  <template>
                    <span
                      v-if="data.level != 3"
                      class="breakWord breakWord2 beyondDisplay1"
                      v-text="data.name"
                    ></span>
                    <el-tooltip
                      v-else
                      :content="data.name"
                      placement="top"
                      effect="dark"
                    >
                      <span
                        class="breakWord breakWord2 beyondDisplay1"
                        v-text="data.name"
                      ></span>
                    </el-tooltip>
                  </template>
                </div>
              </el-tree>
            </div>
          </el-main>
        </el-container>
      </el-tab-pane>
      <el-tab-pane name="second">
        <span slot="label">选填设置 </span>
        <el-tabs
          :tab-position="tabPosition"
          @tab-click="handleClick2"
          v-model="activeName2"
          v-show="formData.id != null"
        >
          <el-tab-pane label="选填模块" name="module">
            <editOptional
              v-if="evaluationGroupDialog"
              :evaluationGroupDialog="evaluationGroupDialog"
              :type="typeDialog"
              v-on:listenEnevaluationGroupClose="listenEnevaluationGroupClose"
              :info="evaluationGroupInfo"
            ></editOptional>
          </el-tab-pane>
          <el-tab-pane label="选填细分模块" name="subMmodule">
            <editOptional
              v-if="evaluationGroupDialog"
              :evaluationGroupDialog="evaluationGroupDialog"
              :type="typeDialog"
              v-on:listenEnevaluationGroupClose="listenEnevaluationGroupClose"
              :info="evaluationGroupInfo"
            ></editOptional>
          </el-tab-pane>
          <el-tab-pane label="选填评估要素" name="moduleItem">
            <editOptional
              v-if="evaluationGroupDialog"
              :evaluationGroupDialog="evaluationGroupDialog"
              :type="typeDialog"
              v-on:listenEnevaluationGroupClose="listenEnevaluationGroupClose"
              :info="evaluationGroupInfo"
            ></editOptional>
          </el-tab-pane>
        </el-tabs>
      </el-tab-pane>
    </el-tabs>

    <!-- <div class="dialog-footer">
      <el-button @click="dialogClose">取消</el-button>
      <el-button type="primary" @click="sure">确认</el-button>
    </div> -->
    <el-dialog
      class="edit"
      :title="title"
      :visible="evaluationElementsShow"
      center
      @close="dialogClose2"
      width="1000px"
      :close-on-click-modal="false"
      append-to-body
    >
      <div class="right">
        <el-divider content-position="center">设置评分标准</el-divider>
        <div class="itemInfo" v-show="itemInfo">
          <span>评估元素:</span>
          <span> {{ itemInfo.name }}</span>
        </div>

        <el-form
          :model="evaluationElementsFormData"
          ref="editEvaluationElementCriterion"
          :rules="evaluationElementsRules"
          class="addDailog2"
          onsubmit="return false;"
          label-width="140px"
          label-position="right"
        >
          <el-form-item label="评估标准" prop="criterion">
            <el-input
              type="textarea"
              show-word-limit
              v-model="evaluationElementsFormData.criterion"
            ></el-input>
          </el-form-item>
          <el-form-item label="分值" prop="evaluationElementsList">
            <div
              class="evaluationElementsList flexJsbAc"
              v-for="(item, index) in evaluationElementsFormData.rawOption"
              :key="index"
            >
              <el-input-number
                type="text"
                class="one"
                placeholder="请输入分值"
                v-model.number="item.min"
                @keyup.native="number($event)"
              >
              </el-input-number
              ><span class="one_">-</span>
              <el-input-number
                type="text"
                class="one"
                placeholder="请输入分值"
                v-model.number="item.max"
                @keyup.native="number($event)"
              >
              </el-input-number>
              <div class="evaluationElementsScore">
                <i
                  @click="removeEvaluationElementsScore(index)"
                  class="el-icon-remove-outline"
                ></i>
                <i
                  @click="addEvaluationElementsScore(index)"
                  class="el-icon-circle-plus-outline"
                ></i>
              </div>
            </div>
          </el-form-item>
          <el-form-item label="" label-width="0" class="center">
            <el-button type="primary" @click="setEvaluationTemplateItem"
              >保存评分标准</el-button
            >
          </el-form-item>
        </el-form>
      </div>
    </el-dialog>
  </el-dialog>
</template>

<script>
import Vue from "vue";
import {
  addEvaluationTemplate,
  editEvaluationTemplate,
  evaluationTemplateTreeDetail,
  addEvaluationTemplateItem,
  editEvaluationTemplateItem,
  addEvaluationTreeElement,
  editEvaluationTreeElement,
  evaluationTemplateItemDetail,
  deleteEvaluationTreeElement,
  // evaluationTemplateItemSelectList,
  addEvaluationTemplateGroup,
  editEvaluationTemplateGroup,
  deleteEvaluationTemplateGroup,
  evaluationTemplateItemGroupDetail,
  getEvaluationTemplateGroupList,
  evaluationTemplateModuleSelectList,
  evaluationTemplateSubModuleSelectList,
  evaluationTemplateItemSelectList,
  editEvaluationTemplateTreeSort,
} from "@/api/evaluationTemplate";

import editOptional from "@/components/evaluationTemplate/editOptional";
export default {
  name: "edit",
  data() {
    return {
      tabPosition: "left",
      title: "新增",
      newTree: null,
      formData: {
        name: null,
        description: null,
        treeElements: [
          // {
          //   name: "模块",
          //   level: "1",
          //   id: "E" + new Date().getTime(),
          //   isEdit: 1,
          //   children: [],
          // },
        ],
      },
      optionalFormData: {
        name: null,
        type: null,
        action: 1,
        selected: null,
        templateId: null,
        targetIdList: [],
        selectedAction: "1",
      },
      rules: {
        name: [{ required: true, message: "请输入模版名称", trigger: "blur" }],
        description: [
          { required: false, message: "请输入模版描述", trigger: "blur" },
        ],
        data: [{ required: false, message: "", trigger: "blur" }],
      },
      evaluationElementsRules: {
        criterion: [
          { required: true, message: "请输入评分标准", trigger: "blur" },
        ],
        data: [{ required: true, message: "请输入模版描述", trigger: "blur" }],
        a: [
          {
            required: true,
            message: "请输入分值",
            type: "number",
            trigger: "blur",
          },
        ],
      },
      // defaultProps: {
      //   children: "children",
      //   label: "name",
      // },

      filterText: "",
      evaluationElementsShow: false,
      evaluationElementsFormData: {
        criterion: null,
        rawOption: [
          {
            min: null,
            max: null,
          },
        ],
      },
      itemInfo: {
        name: null,
      },
      vueTreeListExpanded: false,
      modularTop: true,
      treeDetail: null,

      //
      newname: "",
      defaultProps: {
        children: "children",
        label: "name",
      },
      //下拉框
      evaluationTemplateModuleOptions: [],
      evaluationTemplateSubModuleOptions: [],
      evaluationTemplatItemOptions: [],
      //
      evaluationGroup: null,
      activeName: "first",
      activeName2: "module",
      //
      evaluationGroupDialog: false,
      typeDialog: null,
      evaluationGroupInfo: null,
      dragNodeInfo: {
        currentId: null,
        prevId: null,
        nextId: null,
      },
      infoType: null,
      appendStatus: false,
      fullscreenLoading: false,
    };
  },
  props: {
    type: String,
    editDialog: Boolean,
    info: Object,
  },
  watch: {
    formData: {
      handler(newVal, old) {
        // if ((newVal.name || newVal.description) && this.type == "edit" || (this.type == "add" && this.formData.id)) {
        //   let data = {
        //     id: this.info.id,
        //     name: newVal.name,
        //     description: newVal.description,
        //   };
        //   this.editEvaluationTemplate(data);
        // }
      },
      immediate: true,
      deep: true,
    },
    info: {
      handler(newVal, old) {
        if (newVal && (this.type == "edit" || this.type == "copy")) {
          let data = {
            id: this.info.id,
          };
          if (this.info.type == "copy") {
            this.infoType = true;
          }
          this.evaluationTemplateTreeDetail(data);
        }
      },
      immediate: true,
      deep: true,
    },
    filterText(val) {
      this.$refs.tree.filter(val);
    },
  },
  components: {
    editOptional,
  },
  created() {
    // this.getQualityList();
    if (this.type == "edit") {
      if (!this.info.name) {
        this.title = "评估模版编辑";
      } else {
        this.title = "评估模版编辑" + " —— " + this.info.name;
      }
    } else if (this.type == "add") {
      this.title = "评估模版新增";
    } else if (this.type == "copy") {
      this.title = "评估模版新增";
    }
  },
  methods: {
    //监听编辑弹窗关闭
    listenEnevaluationGroupClose(params) {
      console.log(params);
      const { type, isClose, info } = params;
      this.evaluationGroupDialog = !isClose;
      this.getEvaluationTemplateList(this.pageNum, this.pageSize);

      // if (type == "sure") {
      //   this.getEvaluationTemplateList(this.pageNum, this.pageSize);
      // } else if (type == "cancel") {
      //   // this.getEvaluationTemplateList(this.pageNum, this.pageSize);
      // }
    },
    openDialog(type, row) {
      if (type == "add") {
        console.log(this.optionalFormData);
      } else if (type == "edit") {
      }
    },
    deleteOptionalOne(id) {
      this.$confirm("确定要删除此记录, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
        center: false,
      })
        .then(() => {
          let info = {
            id: id,
          };
          deleteEvaluationTemplateGroup(info).then((response) => {
            if (response.success) {
              this.$message({
                type: "success",
                message: "删除成功!",
              });
              let info = {
                templateId: this.formData.id,
                type:
                  this.activeName2 == "module"
                    ? "1"
                    : this.activeName2 == "subMmodule"
                    ? "2"
                    : this.activeName2 == "moduleItem"
                    ? "3"
                    : "",
              };
              this.getEvaluationTemplateGroupList(info);
            }
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
    //下拉框 start
    evaluationTemplateModuleSelectList(cb) {
      if (cb) {
        evaluationTemplateModuleSelectList({
          templateId: this.formData.id,
        }).then((response) => {
          if (response.success) {
            this.evaluationTemplateModuleOptions = response.data;
          }
        });
      }
    },

    chooseModule(id) {
      var that = this;
      console.log(id);
      // if ((this.type = "edit")) {
      // if (this.params.originalWorkflowId) {
      //   this.projecTrackDetail();
      // } else {
      //   this.$message({ type: "warning", message: "请选择流程!" });
      // }
      // }
    },
    //end
    //选填——分组详情 start
    getEvaluationTemplateGroupList(data) {
      getEvaluationTemplateGroupList(data).then((response) => {
        if (response.success) {
          this.evaluationGroup = response.data;
        }
      });
    },
    //end

    // 点击tab
    handleClick(tab, event) {
      console.log(tab.index);
      let index = tab.index;
      if (index == 0) {
      } else if (index == 1) {
        this.optionalFormData.name = this.formData.name;
        let info = {
          templateId: this.formData.id,
          type:
            this.activeName2 == "module"
              ? "1"
              : this.activeName2 == "subMmodule"
              ? "2"
              : this.activeName2 == "moduleItem"
              ? "3"
              : "",
          templateName: this.formData.name,
        };
        this.evaluationGroupInfo = info;
        this.evaluationGroupDialog = true;
        // this.getEvaluationTemplateGroupList(info);
      }
    },
    handleClick2(tab, event) {
      this.evaluationGroupDialog = false;
      console.log(tab.index);
      let index = tab.index;
      if (index == 0) {
      } else if (index == 1) {
        // this.optionalFormData.name = this.formData.name;
      }
      let info = {
        templateId: this.formData.id,
        type: Number(index) + 1,
        templateName: this.formData.name,
      };
      this.evaluationGroupInfo = info;
      this.evaluationGroupDialog = true;
      // this.getEvaluationTemplateGroupList(info);
    },
    editEvaluationTemplateName(e, type) {
      if (this.type == "edit" || (this.type == "add" && this.formData.id)) {
        console.log(e.target.value);
        console.log(type);
        var value = e.target.value;
        var data;
        if (type == "name" && value != this.formData2.name) {
          data = {
            id: this.formData.id,
            name: this.formData.name,
          };
          this.editEvaluationTemplate(data);
        } else if (
          type == "description" &&
          value != this.formData2.description
        ) {
          data = {
            id: this.formData.id,
            description: this.formData.description,
          };
          this.editEvaluationTemplate(data);
        }
      }
    },
    //start

    handleDragStart(node, ev) {
      // console.log("drag start", node.data.name);
      // console.log("drag start", ev);
    },
    handleDragEnter(draggingNode, dropNode, ev) {
      // console.log("tree drag enter: ", dropNode.data.name);
    },
    handleDragLeave(draggingNode, dropNode, ev) {
      // console.log("tree drag leave: ", dropNode.data.name);
    },
    handleDragOver(draggingNode, dropNode, ev) {
      // console.log("tree drag over: ", dropNode.data.name);
      // this.dragNodeInfo.currentId = dropNode.data.bizId;
    },
    handleDragEnd(draggingNode, dropNode, dropType, ev) {
      var that = this;
      console.log("tree drag dropType: ", dropType);
      // console.log("tree drag end dropNode: ", dropNode);
      // console.log("tree drag end draggingNode: ", draggingNode);
      if (this.type == "edit" || (this.type == "add" && this.formData.id)) {
        let data = dropNode.parent.data.children;
        var nodeBizId = draggingNode.data.bizId;

        if (dropType == "before") {
          // console.log("tree drag end draggingNode: ", draggingNode.data.name);
          // console.log("tree drag end dropNode: ", dropNode.data.name);
          // console.log("tree drag end dropNode: ", dropNode);
        } else if (dropType == "after") {
          console.log("tree drag end draggingNode: ", draggingNode.data.name);
          // console.log("tree drag end dropNode: ", dropNode.data.name);
          // console.table(
          //   "tree drag end dropNode: ",
          //   dropNode.parent.data.children
          // );
        }
        that.updateEvaluationElement();
      }
    },
    handleDrop(draggingNode, dropNode, dropType, ev) {
      // console.log("tree drop: ", dropNode.data.name, dropType);
    },
    allowDrop(draggingNode, dropNode, type) {
      // console.log(type);
      if (draggingNode.level === dropNode.level) {
        if (draggingNode.parent.id === dropNode.parent.id) {
          // 向上拖拽 || 向下拖拽
          return type === "prev" || type === "next";
        }
      } else {
        // 不同级进行处理
        return false;
      }
    },
    allowDrag(draggingNode) {
      return true;
      // if (this.type == "edit") {
      //   return false;
      // } else {
      //   return true;
      // }
    },

    append(node, data) {
      // var pid = data.parentApiGroupId + ':' + data.id
      var timestamp = new Date().getTime();
      let level = Number(data.level) + 1;
      if (Number(data.level) == 3) {
        return false;
      }
      const newChild = {
        id: "E" + timestamp,
        isEdit: 1,
        name: "E" + timestamp,
        children: [],
        level: level,
      };
      if (!data.children) {
        this.$set(data, "children", []);
      }
      data.children.push(newChild);
      this.$nextTick(() => {
        this.$refs.submitEditInput.focus();
      });
      this.appendStatus = true;
    },
    remove(node, data) {
      this.$confirm("确定要删除[" + data.name + "] 吗？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
        center: false,
      })
        .then(() => {
          if (this.type == "edit" || (this.type == "add" && this.formData.id)) {
            deleteEvaluationTreeElement({ id: data.id }).then((response) => {
              if (response.success) {
                this.$message({ type: "success", message: "删除成功!" });
                this.evaluationTemplateTreeDetail({ id: this.formData.id });
                this.removeEvaluationElementOne(node, data);
                // this.$notify({
                //   title: "警告",
                //   message: "选填分组可能发生改变，请检查设置！",
                //   type: "warning",
                // });
              } else {
                this.$message({
                  type: "info",
                  message: response.message,
                });
              }
            });
          } else {
            this.removeEvaluationElementOne(node, data);
          }
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
    // remove(node, data) {
    //   console.log(data.id);
    //   let info = {
    //     id: data.id,
    //   };
    //   deleteEvaluationTreeElement(info).then((response) => {
    //     if (response.success) {
    //       this.$message({ type: "success", message: "删除成功!" });
    //       this.removeEvaluationElementOne(node, data);
    //     }
    //   });
    // },
    removeEvaluationElementOne(node, data) {
      console.log(data.level);
      const parent = node.parent || node.data;
      var children;
      if (data.level == 1) {
        children = parent.data ? parent.data : parent.data.children;
      } else {
        if (parent.data.length > 0) {
          console.log("parent.data");
          children = parent.data;
          console.log(children);
        }
        if (parent.data.children.length > 0) {
          console.log("parent.data.children");
          children = parent.data.children;
          console.log(children);
        }
      }
      const index = children.findIndex((d) => d.id == data.id);
      children.splice(index, 1);
      console.log(index);
    },

    edit(node, data) {
      console.log(
        "before:",
        data.id,
        // data.parentApiGroupId,
        data.name,
        data.isEdit
      );
      this.$set(data, "isEdit", 1);
      this.newname = data.name;
      this.$nextTick(() => {
        this.$refs.input.focus();
      });
      console.log("after:", data.id, data.name, data.isEdit);
    },

    submitEdit(node, data) {
      console.log("点击了保存按钮");
      console.log(node);
      console.log(data);
      console.log(this.appendStatus);
      let parentId = node.parent.data.id || null;
      console.log(parentId);

      if (data.name == this.newname) {
        console.log("没有修改");
        this.newname = "";
        this.$set(data, "isEdit", 0);
      } else {
        this.$set(data, "name", this.newname);
        this.newname = "";
        this.$set(data, "isEdit", 0);
        if (this.type == "edit" || (this.type == "add" && this.formData.id)) {
          let infoAdd = {
            name: data.name,
            type: data.level,
            templateId: this.formData.id,
            parentId: parentId,
            sort: null,
          };
          let info = {
            id: data.id,
            name: data.name,
          };
          console.log(data);
          if (data.id.indexOf("E") != "-1") {
            this.addEvaluationTreeElement(infoAdd);
          } else {
            this.editEvaluationTreeElement(info);
          }
          if (this.appendStatus) {
          }
        }
      }
    },

    cancelEdit(node, data) {
      // console.log('放弃编辑')
      // console.log(data.id, data.name)
      this.newname = "";
      this.$set(data, "isEdit", 0);
    },
   
    updateEvaluationElement() {
       console.log(this.formData.treeElements);
      let treeElementsList = [];
      const treeElementsData = JSON.parse(
        JSON.stringify(this.formData.treeElements)
      );
      treeElementsData.forEach(function (item, index) {
        treeElementsList.push(item.id);
        if (item.children.length > 0) {
          item.children.forEach(function (item2, index) {
            treeElementsList.push(item2.id);
            if (item2.children.length > 0) {
              item2.children.forEach(function (item3, index) {
                treeElementsList.push(item3.id);
              });
            }
          });
        }
      });
      // console.log(treeElementsList);
      editEvaluationTemplateTreeSort({elementIdList:treeElementsList})
        .then((response) => {
          console.log(response);
          if (response.success) {
            this.$message({ type: "success", message: "拖拽成功!" });
            this.evaluationTemplateTreeDetail({ id: this.formData.id });
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },

    nodeclick(node, data, obj) {
      console.log("点击了：", node.id, node.name);

      // this.$store.dispatch("appium/changeApiGroupId", node.id);
      // console.log(this.$store.getters.apiGroupId);
    },
    //end

    //评估元素、分值 start
    number(e) {
      console.log(e.target.value);
      let value = e.target.value;
      let flag = new RegExp("^[1-9]([0-9])*$").test(e.target.value);
      console.log(flag);
      if (value != "") {
        if (!flag) {
          value = parseInt(value);
          this.$message({
            showClose: true,
            message: "请输入正整数",
            type: "warning",
          });
        }
      } else {
        value = null;
      }
    },
    addEvaluationElementsScore(index) {
      console.log(this.evaluationElementsFormData.rawOption[index]);
      let min;
      if (this.evaluationElementsFormData.rawOption[index].max) {
        min = this.evaluationElementsFormData.rawOption[index].max + 1;
      } else {
        min = null;
      }
      let one = {
        min: min,
        max: min + 1,
      };
      this.evaluationElementsFormData.rawOption.push(one);
    },
    removeEvaluationElementsScore(index) {
      if (index > 0) {
        this.evaluationElementsFormData.rawOption.splice(index, 1);
      }
    },
    dialogClose2() {
      this.evaluationElementsShow = false;
    },
    evaluationElementsClick2(event, data, node, row) {
      if (event.level == "3") {
        let info = {
          id: event.bizId,
        };
        this.itemInfo = event;
        this.evaluationTemplateItemDetail(info);
        this.evaluationElementsShow = true;
      } else {
        this.evaluationElementsShow = false;
      }
    },
    filterNode(value, data) {
      if (!value) return true;
      return data.label.indexOf(value) !== -1;
    },
    setEvaluationTemplateItem() {
      console.log(this.evaluationElementsFormData);

      this.$refs["editEvaluationElementCriterion"].validate((valid) => {
        if (valid) {
          var rawOption = JSON.parse(
            JSON.stringify(this.evaluationElementsFormData.rawOption)
          );
          rawOption.forEach(function (item, index) {
            if (item.max) {
              rawOption[index] = String(item.min) + "-" + String(item.max);
            } else {
              rawOption[index] = String(item.min);
            }
          });

          let data = {
            id: this.itemInfo.bizId,
            criterion: this.evaluationElementsFormData.criterion,
            rawOption: JSON.stringify(rawOption),
          };
          this.editEvaluationTemplateItem(data);
        }
      });
    },
    //评估元素、分值 end

    //添加一级模块
    addNode() {
      let timestamp = new Date().getTime();
      let node = {
        name: "模块",
        level: "1",
        id: "E" + timestamp,
        children: [],
        isEdit: 1,
      };
      if (!this.formData.treeElements.children)
        this.formData.treeElements.children = [];
      this.formData.treeElements.push(node);
      this.$nextTick(() => {
        this.$refs.submitEditInput.focus();
      });
    },
    //上一步
    getLastStep() {
      this.modularTop = true;
    },
    //下一步
    getNewTree() {
      this.$refs["editEvaluationTemplate"].validate((valid) => {
        if (valid) {
          this.fullscreenLoading = true;
          var that = this;
          let list = that.formData.treeElements;
          let data = {
            name: that.formData.name,
            description: that.formData.description,
            treeElements: list,
          };
          if (that.type == "add" || that.type == "copy") {
            if (!that.formData.id) {
              that.addEvaluationTemplate(data);
            } else {
              that.evaluationTemplateTreeDetail({ id: this.formData.id });
              // that.modularTop = false;
            }
          } else {
            that.evaluationTemplateTreeDetail({ id: this.formData.id });
            that.modularTop = false;
          }
        } else {
          this.fullscreenLoading = false;
        }
      });
    },

    //弹窗关闭
    dialogClose() {
      this.formData = {};
      this.$emit("listenEditClose", { type: "cancel", isClose: true });
    },

    //新建模块
    addEvaluationTemplate(data) {
      console.log(data);
      addEvaluationTemplate(data)
        .then((response) => {
          if (response.success) {
            this.$message({ type: "success", message: "添加成功!" });
            this.evaluationTemplateTreeDetail(response.data);
          } else {
            console.log("iouiouio");
            this.fullscreenLoading = false;
          }
        })
        .catch(() => {
          this.fullscreenLoading = false;
        });
    },
    editEvaluationTemplate(data) {
      editEvaluationTemplate(data).then((response) => {
        if (response.success) {
          this.$message({ type: "success", message: "编辑成功!" });
        }
      });
    },
    evaluationTemplateTreeDetail(data) {
      evaluationTemplateTreeDetail(data).then((response) => {
        if (response.success) {
          this.formData = response.data;
          let info = {
            name: this.formData.name,
            description: this.formData.description,
          };
          this.formData2 = JSON.parse(JSON.stringify(info));
          this.newTree = response.data.treeElements;
          if (this.type == "add") {
            this.fullscreenLoading = false;
            this.modularTop = false;
          } else if (this.type == "copy") {
            // this.formData.id = null;
            // this.formData.name = null;
            // this.formData.description = null;
            // this.type == "add"
            this.modularTop = false;
          } else if (this.type == "edit") {
            // this.formData.id = null;
            // this.formData.name = null;
            // this.formData.description = null;
            // this.type == "add"
            // this.modularTop = false;
          }
          if (this.infoType == true) {
            this.formData.name = null;
            this.formData.description = null;
            this.infoType = false;
          }
        }
      });
    },
    evaluationTemplateItemDetail(data) {
      evaluationTemplateItemDetail(data).then((response) => {
        if (response.success && response.data) {
          if (response.data.rawOption) {
            var evaluationElementsFormData = response.data;
            evaluationElementsFormData.rawOption = JSON.parse(
              evaluationElementsFormData.rawOption
            );
            evaluationElementsFormData.rawOption.forEach(function (
              item,
              index
            ) {
              let one = item.split("-");
              console.log(one);
              let info = {
                min: one[0],
                max: one[1],
              };
              evaluationElementsFormData.rawOption[index] = info;
              // rawOption[index] = String(item.min) + "-" + String(item.max);
            });
            this.evaluationElementsFormData = evaluationElementsFormData;
          } else {
            this.$refs["editEvaluationElementCriterion"].resetFields();
            this.evaluationElementsFormData = {};
            this.evaluationElementsFormData = response.data;
            this.evaluationElementsFormData.rawOption = [
              {
                min: 0,
                max: 1,
              },
            ];
          }
        } else {
          this.$refs["editEvaluationElementCriterion"].resetFields();
          this.evaluationElementsFormData = {};
          this.evaluationElementsFormData.rawOption = [
            {
              min: 0,
              max: 1,
            },
          ];
        }
      });
    },
    addEvaluationTreeElement(data) {
      addEvaluationTreeElement(data).then((response) => {
        if (response.success) {
          this.$message({ type: "success", message: "新增成功!" });
          this.evaluationTemplateTreeDetail({ id: this.formData.id });
        }
      });
    },
    editEvaluationTreeElement(data) {
      editEvaluationTreeElement(data).then((response) => {
        if (response.success) {
          this.$message({ type: "success", message: "编辑成功!" });
        }
      });
    },
    addEvaluationTemplateItem(data) {
      addEvaluationTemplateItem(data).then((response) => {
        if (response.success) {
          this.$message({ type: "success", message: "新增成功!" });
        }
      });
    },
    editEvaluationTemplateItem(data) {
      editEvaluationTemplateItem(data).then((response) => {
        if (response.success) {
          this.$refs["editEvaluationElementCriterion"].resetFields();
          this.itemInfo = {};
          this.evaluationElementsFormData = {};
          this.evaluationElementsFormData.rawOption = [
            {
              min: 0,
              max: 1,
            },
          ];
          this.$message({ type: "success", message: "编辑成功!" });
          this.evaluationElementsShow = false;
        }
      });
    },

    // //确定
    // sure() {
    //   this.$refs["editEvaluationTemplate"].validate((valid) => {
    //     if (valid) {
    //       if (this.type == "add") {
    //         this.addEvaluationTemplate();
    //       } else if (this.type == "edit") {
    //         this.editEvaluationTemplate();
    //       }
    //     } else {
    //       console.log("-------------error");
    //     }
    //   });
    // },
  },
};
</script>

<style lang="scss" scoped>
.bigTitle {
  font-size: 20px;
  margin-bottom: 15px;
}
.red {
  color: red;
}
.addDailog {
  // padding: 0 0 20px 0;
  display: flex;
  flex-wrap: wrap;
  .roleCodeList {
    width: 90% !important;
    height: auto;
    text-align: center !important;
  }
  .el-form-item {
    text-align: left;

    // display: flex;
    width: 48%;
    // float: left;
    min-height: 1rem;
    .el-select {
      width: 100% !important;
    }
    .el-input-number {
      width: 100% !important;
      text-align: left !important;
      .el-input__inner {
        text-align: left !important;
      }
    }
  }
}
.addDailog2 {
}
.el-container {
  min-height: 25rem;
  overflow-y: auto;
  .el-header {
    height: auto !important;
    .el-input {
      height: 1rem !important;
      input {
        height: 1rem !important;
      }
    }
  }
}
.treeInput {
}
.el-input__inner {
  height: 1rem !important;
}

// start

.vtl {
  .vtl-drag-disabled {
    background-color: #d0cfcf;
    &:hover {
      background-color: #d0cfcf;
    }
  }
  .vtl-disabled {
    background-color: #d0cfcf;
  }
}

.icon {
  // font-size: 1rem;
  margin: 0 0.3rem;
  &:hover {
    cursor: pointer;
  }
  .icon {
    width: 1rem;
  }
}

.muted {
  color: gray;
  font-size: 80%;
}

// end

.modularCenter {
  text-align: center;
  margin-bottom: 10px;
}
.modularButton {
  // margin: 0.2rem 0;
}
.modularBottom {
  // width: 100%;
  .left {
    // width: 40%;
    align-self: flex-start !important;
  }
}
.right {
  // width: 60%;
  align-self: flex-start !important;
  .evaluationElementsList {
    margin: 5px 0;
    .one {
      width: 39%;
      // margin: 0 5px;
    }
    .one_ {
      margin: 0 5px;
    }
    .evaluationElementsScore {
      width: 12% !important;
      // float: right;
      text-align: center;
      i {
        margin: 0 3px;
      }
    }
  }
  .center {
    text-align: center;
  }
}
.el-tree-node__content {
  width: 100%;
}
.custom-tree-node {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 16px;
  width: 60%;

  // padding-right: 20px;
  /* 修改el-input高度，方案一： */
  /* 某些预处理器(sass)无法识别>>>，建议使用方案二 */
  /* >>> .el-input__inner {
  height: 20px;
} */
  /* 修改el-input高度，方案二： */
  /deep/ .el-input__inner {
    height: 20px;
    // width: 80%;
  }

  .breakWord2 {
    width: 80%;
    text-align: left;
  }
}

i {
  font-size: 18px;
  margin: 0 3px;
}
.itemInfo {
  margin-bottom: 1rem;
  span:first-child {
    font-weight: bold;
    font-size: 16px;
  }
}
.span-tag-list {
  .item:last-child .d {
    display: none;
  }
}
.evaluationGroup {
  height: 10rem;
}
.itemOne {
  margin-bottom: 0px !important;
  color: #909399 !important;
  line-height: 20px !important;
  .el-form-item__content {
    line-height: 20px !important;
    span {
      font-size: 12px !important;
    }
  }
}
</style>
