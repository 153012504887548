import request from '@/utils/request'

//模版 start
export function getEvaluationTemplateList(param) {
    return request({
        url: '/admin/evaluation/template/paging-list',
        method: 'post',
        data: param
    })
}
//增加评估模板
export function addEvaluationTemplate(param) {
    return request({
        url: '/admin/evaluation/template/add',
        method: 'post',
        data: param
    })
}
//编辑单个评估模板
export function editEvaluationTemplate(param) {
    return request({
        url: '/admin/evaluation/template/edit',
        method: 'post',
        data: param
    })
}
 //评估模板详情
export function evaluationTemplateDetail(param) {
    return request({
        url: '/admin/evaluation/template/detail',
        method: 'post',
        data: param
    })
}

export function evaluationTemplateTableDetail(param) {
    return request({
        url: '/admin/evaluation/template/table-detail',
        method: 'post',
        data: param
    })
}
 //评估模板删除
 export function deleteEvaluationTemplate(param) {
    return request({
        url: '/admin/evaluation/template/delete',
        method: 'post',
        data: param
    })
}
//评估模板树状详情
export function evaluationTemplateTreeDetail(param) {
    return request({
        url: '/admin/evaluation/template/tree-detail',
        method: 'post',
        data: param
    })
}
//编辑时--增加评估模板评估元素的分值
export function addEvaluationTemplateItem(param) {
    return request({
        url: '/admin/evaluation/template/item/add',
        method: 'post',
        data: param
    })
}
//编辑时--编辑评估模板评估元素的分值
export function editEvaluationTemplateItem(param) {
    return request({
        url: '/admin/evaluation/template/item/edit',
        method: 'post',
        data: param
    })
} 
 
// 评估模板评估元素 下拉框
export function evaluationTemplateItemSelectList(param) {
    return request({
        url: '/admin/evaluation/template/item/select-list',
        method: 'post',
        data: param
    })
} 
//编辑时---增加评估模板的某一个树元素
export function addEvaluationTreeElement(param) {
    return request({
        url: '/admin/evaluation/template/tree-element/add',
        method: 'post',
        data: param
    })
}
//编辑时---编辑评估模板的某一个树元素
export function editEvaluationTreeElement(param) {
    return request({
        url: '/admin/evaluation/template/tree-element/edit',
        method: 'post',
        data: param
    })
} 

//编辑时---删除评估模板的某一个树元素
export function deleteEvaluationTreeElement(param) {
    return request({
        url: '/admin/evaluation/template/tree-element/delete',
        method: 'post',
        data: param
    })
} 
//评估模板树状详情
export function evaluationTemplateItemDetail(param) {
    return request({
        url: '/admin/evaluation/template/item/detail',
        method: 'post',
        data: param
    })
}
//模版 end


//评估模板分组 start

export function addEvaluationTemplateGroup(param) {
    return request({
        url: 'admin/evaluation/template/group/add',
        method: 'post',
        data: param
    })
}
export function editEvaluationTemplateGroup(param) {
    return request({
        url: '/admin/evaluation/template/group/edit',
        method: 'post',
        data: param
    })
} 

export function deleteEvaluationTemplateGroup(param) {
    return request({
        url: '/admin/evaluation/template/group/delete',
        method: 'post',
        data: param
    })
} 
export function evaluationTemplateItemGroupDetail(param) {
    return request({
        url: '/admin/evaluation/template/group/detail',
        method: 'post',
        data: param
    })
}
export function getEvaluationTemplateGroupList(param) {
    return request({
        url: '/admin/evaluation/template/group/list',
        method: 'post',
        data: param
    })
}
//评估模板分组 end

//评估模块下拉列表
export function evaluationTemplateModuleSelectList(param) {
    return request({
        url: '/admin/evaluation/module/select-list',
        method: 'post',
        data: param
    })
}

//评估细分模块下拉列表
export function evaluationTemplateSubModuleSelectList(param) {
    return request({
        url: '/admin/evaluation/sub-module/select-list',
        method: 'post',
        data: param
    })
}


//编辑评估模板的树元素的排序
export function editEvaluationTemplateTreeSort(param) {
    return request({
        url: '/admin/evaluation/template/tree-element/sort/edit',
        method: 'post',
        data: param
    })
}
// {
//     "currentId":"122",
//     "prevId":"153",
//     "nextId":null
// }


// 复制评估模板 
export function copyEvaluationTemplate(param) {
    return request({
        url: '/admin/evaluation/template/copy',
        method: 'post',
        data: param
    })
}

