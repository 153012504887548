<template>
  <el-dialog
    :title="title"
    :visible="detailDialog"
    width="70%"
    class="editDialogs"
    :close-on-click-modal="false"
    @close="dialogClose"
    center
  >
    <div>
      <!-- <el-divider content-position="center">基本资料</el-divider> -->
      <ul class="bar" style="list-style-type: none">
        <li>
          <b>模版描述：</b>
          <span>{{ detail.description }}</span>
        </li>

        <li class="width100" v-show="detail.explainList">
          <b>使用说明：</b>
          <span>
            <div
              style="padding: 0 2px"
              v-for="(item, index) in detail.explainList"
              :key="index"
            >
              <div v-if="detail.explainList.length == 1">
                <p>{{ item }}</p>
                <br />
              </div>
              <div v-else>{{ index + 1 }}. {{ item }}</div>
            </div>
          </span>
        </li>
        <li>
          <b>创建人：</b>
          <span>{{ detail.creatorName }}</span>
        </li>

        <li>
          <b>创建时间：</b>
          <span>{{ detail.createDate | formatDateFilter }}</span>
        </li>
        <li>
          <b>更新人：</b>
          <span>{{ detail.updaterName }}</span>
        </li>

        <li>
          <b>更新时间：</b>
          <span>{{ detail.updateDate | formatDateFilter }}</span>
        </li>
      </ul>
    </div>
    <el-table
      v-loading="tableLoading"
      ref="multipleTable"
      width="100%"
      border
      :span-method="arraySpanMethod"
      :data="tableData"
      style="width: 100%; margin-top: 10px"
      class="detailsTable"
    >
      <el-table-column label="模块" align="center">
        <template slot-scope="scope">
          {{ scope.row.moduleName }}
          <br />
          <span class="explain">
            {{ scope.row.moduleExplain }}
          </span>
        </template>
      </el-table-column>
      <el-table-column label="细分模块" align="center">
        <template slot-scope="scope">
          {{ scope.row.subModuleName }}
          <br />
          <span class="explain">
            {{ scope.row.subModuleExplain }}
          </span>
        </template>
      </el-table-column>
      <el-table-column label="评估要素" align="center">
        <template slot-scope="scope">
          <p>{{ scope.row.question }}</p>
        </template>
      </el-table-column>
      <el-table-column label="评估标准" align="left">
        <template slot-scope="scope">
          <p>{{ scope.row.criterion }}</p>
        </template>
      </el-table-column>
    </el-table>
    <span slot="footer" class="dialog-footer">
      <el-button type="primary" @click="dialogClose">确 定</el-button>
    </span>
  </el-dialog>
</template>

<script>
import { formatDate } from "@/common/date";
import { evaluationTemplateTableDetail } from "@/api/evaluationTemplate";
export default {
  name: "detailsDialog",
  props: {
    type: String,
    detailDialog: Boolean,
    id: String,
    type: String,
    info: Object,
  },
  data() {
    return {
      // 模拟后台返回数据
      tableData: [],
      needMergeArr: ["moduleName", "subModuleName"], // 有合并项的列
      rowMergeArrs: {}, // 包含需要一个或多个合并项信息的对象
      detail: {},
      tableLoading: false,
      loading: false,
      title: null,
    };
  },
  filters: {
    formatDateFilter(time) {
      var date = new Date(time);
      if (time) return formatDate(date, "yyyy-MM-dd hh:mm:ss");
    },
  },
  mounted() {
    this.evaluationTemplateTableDetail();

    // this.rowMergeArrs = this.rowMergeHandle(this.needMergeArr, this.tableData); // 处理数据
  },
  methods: {
    /**
     * @description 实现合并行或列
     * @param row:Object 需要合并的列name 如:'name' 'id'
     * @param column:Object 当前行的行数，由合并函数传入
     * @param rowIndex:Number 当前列的数据，由合并函数传入
     * @param columnIndex:Number 当前列的数据，由合并函数传入
     *
     * @return 函数可以返回一个包含两个元素的数组，第一个元素代表rowspan，第二个元素代表colspan。 也可以返回一个键名为rowspan和colspan的对象
     * 参考地址：https://element.eleme.cn/#/zh-CN/component/table#table-biao-ge
     */
    arraySpanMethod({ row, column, rowIndex, columnIndex }) {
      // console.log(column)
      // 没办法循环判断具体是那一列 所以就只好写了多个if
      if (column.label === "模块")
        return this.mergeAction("moduleName", rowIndex, column);
      if (column.label === "细分模块")
        return this.mergeAction("subModuleName", rowIndex, column);
    },
    /**
     * @description 根据数组来确定单元格是否需要合并
     * @param val:String 需要合并的列name 如:'name' 'id'
     * @param rowIndex:Number 当前行的行数，由合并函数传入
     * @param colData:Object 当前列的数据，由合并函数传入
     *
     * @return 返回值为一个数组表示该单元格是否需要合并; 说明: [0,0]表示改行被合并了 [n+,1]n为1时表示该单元格不动,n大于1时表示合并了N-1个单元格
     */
    mergeAction(val, rowIndex, colData) {
      let _row = this.rowMergeArrs[val].rowArr[rowIndex];
      let _col = _row > 0 ? 1 : 0;
      return [_row, _col];
    },
    /**
     * @description 根据数组将指定对象转化为相应的数组
     * @param arr:Array[String] 必填 必须是字符串形式的数组
     * @param data:Array 必填 需要转化的对象
     *
     * @return 返回一个对象
     * 例：rowMerge(['name','value'], [{value:'1', name:'小明'}, {value:'2', name:'小明'}, {value:'3', name:'小明'}, {value:'1', name:'小明'}, {value:'1', name:'小明'}])
     * 返回值: {
     *          name:{
     *            rowArr: [5, 0, 0, 0, 0]
     *            rowMergeNum: 0,
     *          },
     *          value: {
     *            rowArr: [1, 1, 1, 2, 0],
     *            rowMergeNum: 3
     *          }
     *        }
     */
    rowMergeHandle(arr, data) {
      if (!Array.isArray(arr) && !arr.length) return false;
      if (!Array.isArray(data) && !data.length) return false;
      let needMerge = {};
      arr.forEach((i) => {
        needMerge[i] = {
          rowArr: [],
          rowMergeNum: 0,
        };
        data.forEach((item, index) => {
          if (index === 0) {
            needMerge[i].rowArr.push(1);
            needMerge[i].rowMergeNum = 0;
          } else {
            if (item[i] === data[index - 1][i]) {
              needMerge[i].rowArr[needMerge[i].rowMergeNum] += 1;
              needMerge[i].rowArr.push(0);
            } else {
              needMerge[i].rowArr.push(1);
              needMerge[i].rowMergeNum = index;
            }
          }
        });
      });
      return needMerge;
    },

    dialogClose() {
      this.$emit("listenDetailClose", {
        type: "cancel",
        isClose: true,
      });
    },
    evaluationTemplateTableDetail() {
      this.tableLoading = true;
      var that = this;
      let info = {
        id: this.info.id,
      };
      evaluationTemplateTableDetail(info).then((res) => {
        if (res.success) {
          // console.log(res.data);
          this.detail = res.data;
          this.title = this.detail.name;
          this.tableData = res.data.tableDetails;
          this.tableLoading = false;

          // this.getSpanArr(this.detail.tableDetails);
          this.rowMergeArrs = this.rowMergeHandle(
            this.needMergeArr,
            this.tableData
          ); // 处理数据
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.detailsTable {
  color: #000;
  .explain {
    color: #606266;
    font-size: 12px;
  }
}
.version {
  color: #606266;
  font-size: 12px;
  margin-left: 5px;
  // align-self: flex-end;
}
.bar {
  overflow: hidden;
  padding: 10px;
  li {
    width: 49%;
    // min-height: 30px;
    // line-height: 50px;
    padding: 5px 0;
    font-size: 15px;
    list-style: none;
    float: left;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    text-align: left;
    b {
      min-width: 150px !important;
      text-align: right;
      align-self: baseline;
      float: left;
    }
    span {
      width: calc(100% - 160px);
      // display: inline-block;
      // width: 120px;
      // margin-left: 100px;
      text-align: left;
    }
    .childWidth {
      width: calc(100% - 150px);
    }
    div {
      width: calc(100% - 160px);
      text-align: left;
    }
  }
  .width50 {
    width: 50%;
  }
  .width100 {
    width: 100%;
    p {
      width: calc(100% - 120px);
      float: right;
      span {
        display: inline;
        padding: 0 10px;
      }
    }
  }
}
</style>